import { CustomEase, gsap, ScrollToPlugin, ScrollTrigger, TweenLite } from "gsap/all"
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import SEO from '../components/seo'
import GhostButton from '../components/ux/buttons/ghost-button'
import Jumpbutton from "../components/ux/buttons/jumpbutton/jumpbutton"
import { ImageRef } from '../components/ux/image'
import { animationType, buildScrollTriggers, createAnimationType, doAnimate, nl2br, resetElements } from '../utils/functions'

//const plugins = [ ScrollToPlugin, CustomEase];
gsap.registerPlugin(ScrollToPlugin, CustomEase, ScrollTrigger)

function Digitalization({ translations, lang }) {
    /**
     * Refs
     */
    const scrollRef1 = useRef()
    const scrollRef2 = useRef()
    const scrollRef3 = useRef()
    const scrollRef4 = useRef()
    const scrollRef5 = useRef()
    const scrollRef6 = useRef()
    const lightHolderRef = useRef()
    const swingerRef = useRef()

    const page_1_h1Ref = useRef()
    const page_1_textRef = useRef()
    const page_1_imageRef = useRef()

    const page_2_h1Ref = useRef()
    const page_2_textRef = useRef()
    const page_2_imageRef = useRef()

    const page_3_h1Ref = useRef()
    const page_3_textRef = useRef()
    const page_3_imageRef = useRef()

    const page_4_h1Ref = useRef()
    const page_4_textRef = useRef()
    const page_4_imageRef = useRef()

    const page_5_h1Ref = useRef()
    const page_5_textRef = useRef()
    const page_5_imageRef = useRef()

    const page_6_h1Ref = useRef()
    const page_6_textRef = useRef()
    const page_6_imageRef = useRef()

    /**
     * State
     */
    const [rendered, setRendered] = useState(false)

    /**
     * Creates the custom animation for GSAP
     */
    createAnimationType()

    /**
     * Collects all Refs into an array
     */

    const scrollRefs = [
        scrollRef1,
        scrollRef2,
        scrollRef3,
        scrollRef4,
        scrollRef5,
        scrollRef6,
    ];

    // Aktuelle Position
    let currentScrollPos = useRef(1);
    let animationActive = useRef(false);
    let entered = useRef(false);

    // Entsprechender Scrolleffekt
    const scrollEffect = useCallback((step) => {
        var durationFactor = 1;
        var delayFactor = .5;
        animationActive.current = true;
        switch (step) {
            case 1: // Start.
                if (entered.current) {// Zurückanimation
                    TweenLite.to(swingerRef.current, { ease: animationType, xPercent: 0, rotation: -120, duration: 1.5 })
                    TweenLite.to(lightHolderRef.current, { ease: animationType, yPercent: -10, xPercent: 10, duration: 2 })
                } else { // Startanimation
                    TweenLite.from(lightHolderRef.current, { ease: animationType, autoAlpha: 0, duration: 1.5 })
                    TweenLite.from(swingerRef.current, { ease: animationType, xPercent: 50, autoAlpha: 0, rotation: -90, duration: 1 })
                }
                TweenLite.from(page_1_h1Ref.current, { ease: animationType, x: -200, y: -200, autoAlpha: 0, duration: 1 })
                TweenLite.from(page_1_textRef.current, { ease: animationType, x: -200, y: 50, autoAlpha: 0, duration: 1 })
                TweenLite.from(page_1_imageRef.current, {
                    ease: animationType, x: 200, autoAlpha: 0, duration: 1, onComplete: () => {
                        resetElements([page_2_h1Ref.current, page_2_textRef.current, page_2_imageRef.current]); // Nachfolger
                        animationActive.current = false
                    }
                })
                entered.current = true;
                break;
            case 2: // GEWICHTIGE INFORMATIONEN
                // Alte Szene ausblenden
                TweenLite.to(swingerRef.current, { xPercent: -100, rotation: 45, duration: 1.5 });
                TweenLite.to(lightHolderRef.current, { ease: animationType, yPercent: -10, xPercent: -50, duration: 2 })
                TweenLite.to(page_1_h1Ref.current, { ease: animationType, x: -200, y: -200, autoAlpha: 0, duration: durationFactor });
                TweenLite.to(page_1_textRef.current, { ease: animationType, x: -200, y: 50, autoAlpha: 0, duration: durationFactor });
                TweenLite.to(page_1_imageRef.current, { ease: animationType, autoAlpha: 0, x: 200, duration: (durationFactor + .25) });

                // Neue Szene einblenden
                TweenLite.from(page_2_h1Ref.current, { x: 200, y: -200, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(page_2_textRef.current, { x: 100, y: 100, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from('.cta1', { y: 500, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(page_2_imageRef.current, {
                    y: 100, x: -100, duration: durationFactor, autoAlpha: 0, delay: delayFactor, onComplete: () => {
                        resetElements([page_1_imageRef.current, page_1_h1Ref.current, page_1_textRef.current]);
                        animationActive.current = false;
                    }
                });
                break;
            case 3: // HALTE KUNDEN BEI DER STANGE
                // Alte Szene ausblenden
                TweenLite.to(swingerRef.current, { xPercent: 0, yPercent: -10, rotation: 0, duration: 1.5 });
                TweenLite.to(lightHolderRef.current, { ease: animationType, yPercent: -10, xPercent: 10, duration: 2 })
                TweenLite.to(page_2_h1Ref.current, { ease: animationType, y: -400, autoAlpha: 0, duration: durationFactor });
                TweenLite.to(page_2_textRef.current, { ease: animationType, x: 200, y: -350, autoAlpha: 0, duration: durationFactor });
                TweenLite.to('.cta1', { ease: animationType, x: 200, y: -300, autoAlpha: 0, duration: durationFactor });
                TweenLite.to(page_2_imageRef.current, { ease: animationType, autoAlpha: 0, y: -200, duration: (durationFactor + .25) });

                // Neue Szene einblenden
                TweenLite.from(page_3_h1Ref.current, { x: -100, y: 200, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(page_3_textRef.current, { x: -100, y: 400, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from('.cta2', { y: 500, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(page_3_imageRef.current, {
                    y: 100, x: 100, duration: durationFactor, autoAlpha: 0, delay: delayFactor, onComplete: () => {
                        resetElements([page_2_imageRef.current, page_2_h1Ref.current, page_2_textRef.current, '.cta1']);
                        animationActive.current = false;
                    }
                });
                break;
            case 4: // RESTOCK
                // Alte Szene ausblenden
                TweenLite.to(swingerRef.current, { xPercent: -100, rotation: 90, duration: 1.5 });
                TweenLite.to(lightHolderRef.current, { ease: animationType, yPercent: 0, xPercent: -50, duration: 2 })
                TweenLite.to(page_3_h1Ref.current, { ease: animationType, x: -200, y: -300, autoAlpha: 0, duration: durationFactor });
                TweenLite.to(page_3_textRef.current, { ease: animationType, x: -200, y: -250, autoAlpha: 0, duration: durationFactor });
                TweenLite.to('.cta2', { ease: animationType, x: -200, y: -300, autoAlpha: 0, duration: durationFactor });
                TweenLite.to(page_3_imageRef.current, { ease: animationType, autoAlpha: 0, x: 200, duration: (durationFactor + .25) });

                // Neue Szene einblenden
                TweenLite.from(page_4_h1Ref.current, { x: 200, y: -200, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from('.cta3', { y: 500, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(page_4_textRef.current, { x: 100, y: 100, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(page_4_imageRef.current, {
                    y: 100, x: -100, duration: durationFactor, autoAlpha: 0, delay: delayFactor, onComplete: () => {
                        resetElements([page_3_imageRef.current, page_3_h1Ref.current, page_3_textRef.current, '.cta2']);
                        animationActive.current = false;
                    }
                });
                break;
            case 5: // SCHICK' DIE ADS IN DIE DEFI-PHASE
                // Alte Szene ausblenden
                TweenLite.to(swingerRef.current, { xPercent: 0, rotation: 0, duration: 1.5 });
                TweenLite.to(lightHolderRef.current, { ease: animationType, yPercent: -10, xPercent: 10, duration: 2 })
                TweenLite.to(page_4_h1Ref.current, { ease: animationType, y: -400, autoAlpha: 0, duration: durationFactor });
                TweenLite.to('.cta3', { ease: animationType, x: 200, y: -300, autoAlpha: 0, duration: durationFactor });
                TweenLite.to(page_4_textRef.current, { ease: animationType, y: -350, x: 200, autoAlpha: 0, duration: durationFactor });
                TweenLite.to(page_4_imageRef.current, { ease: animationType, autoAlpha: 0, y: -200, duration: (durationFactor + .25) });

                // Neue Szene einblenden
                TweenLite.from(page_5_h1Ref.current, { x: -100, y: 200, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(page_5_textRef.current, { x: -100, y: 400, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(page_5_imageRef.current, {
                    y: 100, x: 100, duration: durationFactor, autoAlpha: 0, delay: delayFactor, onComplete: () => {
                        resetElements([page_4_imageRef.current, page_4_h1Ref.current, page_4_textRef.current, '.cta3']);
                        animationActive.current = false;
                    }
                });
                break;
            case 6: // MEHR KOMMUNIKATION
                // Alte Szene ausblenden
                TweenLite.to(swingerRef.current, { xPercent: -90, rotation: 70, duration: 1.5 });
                TweenLite.to(lightHolderRef.current, { ease: animationType, yPercent: -10, xPercent: -50, duration: 2 })
                TweenLite.to(page_5_h1Ref.current, { ease: animationType, x: -200, y: -200, autoAlpha: 0, duration: durationFactor });
                TweenLite.to(page_5_textRef.current, { ease: animationType, x: -200, y: 50, autoAlpha: 0, duration: durationFactor });
                TweenLite.to(page_5_imageRef.current, { ease: animationType, autoAlpha: 0, x: -200, duration: (durationFactor + .25) });

                // Neue Szene einblenden
                TweenLite.from(page_6_h1Ref.current, { x: 200, y: -200, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(page_6_textRef.current, { x: 100, y: 100, duration: durationFactor, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(page_6_imageRef.current, {
                    y: 100, x: -100, duration: durationFactor, autoAlpha: 0, delay: delayFactor, onComplete: () => {
                        resetElements([page_5_imageRef.current, page_5_h1Ref.current, page_5_textRef.current]);
                        animationActive.current = false;
                    }
                });
                break;
            default:

                break;

        }
    }, []);

    useEffect(() => {
        if (doAnimate()) {
            document.body.classList.add('noscroll');
            TweenLite.defaultEase = animationType;

            if (rendered !== true) {
                setRendered(true);

                // Warten bis alles gerendert ist
                setTimeout(() => {
                    scrollEffect(1);
                    buildScrollTriggers(scrollRefs, scrollEffect, scrollToRef, currentScrollPos, animationActive);
                }, 850)
                setTimeout(() => {
                    document.body.classList.remove('noscroll');
                }, 3000)
            }

            return () => {
                ScrollTrigger.getAll().forEach(t => t.kill());
            }
        }
    }, [scrollEffect, scrollRefs, rendered])

    /**
     * Scroll on Click
     * 
     * @param {ClickEvent} e ClickEvent
     * @returns 
     */
    const clickHandler = (e) => {
        if (!doAnimate()) return
        currentScrollPos.current += 1
        scrollEffect(currentScrollPos.current);
        scrollToRef(scrollRefs[currentScrollPos.current - 1])
    }

    const scrollToRef = (ref, forward = true) => {
        let offset = ref.current.offsetTop + 108;
        if (currentScrollPos.current === 6) { // Bis footer
            offset += 280;
        }
        gsap.to(window, { duration: 2, scrollTo: offset });
    };


    return (
        <Layout>
            <SEO title={translations.digitalization_title} description={translations.digitalization_meta_description} />
            <div className="digitalization subpage">
                <div className="lightholder animationElement" ref={lightHolderRef}></div>
                <div className="swinger animationElement" ref={swingerRef}></div>
                {/* Section 1 - Genial meets digital */}
                <section ref={scrollRef1} className="one">
                    <div className="inner-wrapper flex">
                        <div>
                            <div ref={page_1_h1Ref} className="animationElement">
                                <h1>
                                    {
                                        nl2br(translations.digitalization_page_1_h1_white)
                                    }<br />
                                    <span className="yellow">
                                        {
                                            nl2br(translations.digitalization_page_1_h1_yellow)
                                        }
                                    </span>
                                </h1>
                                <p className="grey">
                                    {
                                        nl2br(translations.digitalization_page_1_h2)
                                    }
                                </p>
                            </div>
                            <div ref={page_1_textRef} className="animationElement">
                                <p className="light">
                                    {
                                        nl2br(translations.digitalization_page_1_text)
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="mb-2 mt-2 animationElement" ref={page_1_imageRef}>
                            <ImageRef className="image_digitalization" img={require('./../images/digitalization/page_1.png')} img2={require('./../images/digitalization/page_1@2x.png')} alt="Network image of the Bodyshake Digital Bodyshake Platform" />
                        </div>
                    </div>
                </section>
                {/* Section 2 - Gewichtige Informationen */}
                <section ref={scrollRef2} className="ptopper">
                    <div className="inner-wrapper flex mt-2">
                        <div className="mb-2 page2-img mobile-exclude animationElement" ref={page_2_imageRef}>
                            <ImageRef className="image_digitalization" img={require('./../images/digitalization/page_2.png')} img2={require('./../images/digitalization/page_2@2x.png')} alt="Cloud image of the Bodyshake Digital Bodyshake Platform" />
                        </div>
                        <div>
                            <div ref={page_2_h1Ref} className="animationElement">
                                <div className="bs-headline">
                                    {
                                        nl2br(translations.digitalization_page_2_h1_white)
                                    }<br />
                                    <span className="yellow">
                                        {
                                            nl2br(translations.digitalization_page_2_h1_yellow)
                                        }
                                    </span>
                                </div>
                                <p className="grey">
                                    {
                                        nl2br(translations.digitalization_page_2_h2)
                                    }
                                </p>
                            </div>
                            <div ref={page_2_textRef} className="animationElement">
                                <p className="light">
                                    {
                                        nl2br(translations.digitalization_page_2_text)
                                    }
                                </p>
                            </div>
                            <GhostButton title={translations.digitalization_page_2_cta} className="mt-2 cta1 animationElement" target={`${lang === 'de' ? '/machines/' : '/en/machines/'}`} />
                        </div>
                        <div className="mb-2 page2-img mobile mt-2">
                            <ImageRef className="image_digitalization" img={require('./../images/digitalization/page_2.png')} img2={require('./../images/digitalization/page_2@2x.png')} alt="Cloud image of the Bodyshake Digital Bodyshake Platform" />
                        </div>
                    </div>
                </section>
                {/* Section 3 - Halte Kunde bei der Stange */}
                <section ref={scrollRef3} className="ptopper">
                    <div className="inner-wrapper flex">
                        <div>
                            <div ref={page_3_h1Ref} className="animationElement">
                                <div className="bs-headline">
                                    {
                                        nl2br(translations.digitalization_page_3_h1_white)
                                    }<br />
                                    <span className="yellow">
                                        {
                                            nl2br(translations.digitalization_page_3_h1_yellow)
                                        }
                                    </span>
                                </div>
                                <p className="grey">
                                    {
                                        nl2br(translations.digitalization_page_3_h2)
                                    }
                                </p>
                            </div>
                            <div ref={page_3_textRef} className="animationElement">
                                <p className="light">
                                    {
                                        nl2br(translations.digitalization_page_3_text)
                                    }
                                </p>
                            </div>
                            <GhostButton title={translations.digitalization_page_3_cta} className="mt-2 cta2" target={`${lang === 'de' ? '/mobile-app/' : '/en/mobile-app/'}`} />
                        </div>
                        <div className="mb-2 page2-img mt-2">
                            <ImageRef className="image_digitalization" ref={page_3_imageRef} img={require('./../images/digitalization/page_3.png')} img2={require('./../images/digitalization/page_3@2x.png')} alt="Cloud image of the Bodyshake Digital Bodyshake Platform" />
                        </div>
                    </div>
                </section>
                {/* Section 4 - ReReReStock */}
                <section ref={scrollRef4} className="ptopper">
                    <div className="inner-wrapper flex">
                        <div className="mb-2 page2-img mobile-exclude animationElement" ref={page_4_imageRef}>
                            <ImageRef className="image_digitalization" img={require('./../images/digitalization/page_4.png')} img2={require('./../images/digitalization/page_4@2x.png')} alt="Cloud image of the Bodyshake Digital Bodyshake Platform" />
                        </div>
                        <div>
                            <div ref={page_4_h1Ref} className="animationElement">
                                <div className="bs-headline">
                                    {
                                        nl2br(translations.digitalization_page_4_h1_white)
                                    }
                                    <span className="yellow">
                                        {
                                            nl2br(translations.digitalization_page_4_h1_yellow)
                                        }
                                    </span>
                                </div>
                                <p className="grey">
                                    {
                                        nl2br(translations.digitalization_page_4_h2)
                                    }
                                </p>
                            </div>
                            <div ref={page_4_textRef} className="animationElement">
                                <p className="light">
                                    {
                                        nl2br(translations.digitalization_page_4_text)
                                    }
                                </p>
                            </div>
                            <GhostButton title={translations.digitalization_page_4_cta} className="mt-2 cta3" target={`${lang === 'de' ? '/refillment/' : '/en/refillment/'}`} />
                        </div>
                        <div className="mb-2 page2-img mobile mt-2">
                            <ImageRef className="image_digitalization" img={require('./../images/digitalization/page_4.png')} img2={require('./../images/digitalization/page_4@2x.png')} alt="Cloud image of the Bodyshake Digital Bodyshake Platform" />
                        </div>
                    </div>
                </section>
                {/* Section 5 - Schick die Ads in die Defi-Phase */}
                <section ref={scrollRef5} className="ptopper">
                    <div className="inner-wrapper flex">
                        <div>
                            <div ref={page_5_h1Ref} className="animationElement">
                                <div className="bs-headline">
                                    {
                                        nl2br(translations.digitalization_page_5_h1_white)
                                    }<br />
                                    <span className="yellow">
                                        {
                                            nl2br(translations.digitalization_page_5_h1_yellow)
                                        }
                                    </span>
                                </div>
                                <p className="grey">
                                    {
                                        nl2br(translations.digitalization_page_5_h2)
                                    }
                                </p>
                            </div>
                            <div ref={page_5_textRef} className="animationElement">
                                <p className="light">
                                    {
                                        nl2br(translations.digitalization_page_5_text)
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="mb-2 page2-img mt-2">
                            <ImageRef className="image_digitalization" ref={page_5_imageRef} img={require('./../images/digitalization/page_5.png')} img2={require('./../images/digitalization/page_5@2x.png')} alt="Cloud image of the Bodyshake Digital Bodyshake Platform" />
                        </div>
                    </div>
                </section>
                {/* Section 6 - Mehr Kommunikation */}
                <section ref={scrollRef6} className="ptopper">
                    <div className="inner-wrapper flex">
                        <div className="mb-2 page2-img mobile-exclude animationElement" ref={page_6_imageRef} >
                            <ImageRef className="image_digitalization" img={require('./../images/digitalization/page_6.png')} img2={require('./../images/digitalization/page_6@2x.png')} alt="Cloud image of the Bodyshake Digital Bodyshake Platform" />
                        </div>
                        <div>
                            <div ref={page_6_h1Ref} className="animationElement">
                                <div className="bs-headline">
                                    {
                                        nl2br(translations.digitalization_page_6_h1_white)
                                    }<br />
                                    <span className="yellow">
                                        {
                                            nl2br(translations.digitalization_page_6_h1_yellow)
                                        }
                                    </span>
                                </div>
                                <p className="grey">
                                    {
                                        nl2br(translations.digitalization_page_6_h2)
                                    }
                                </p>
                            </div>
                            <div ref={page_6_textRef} className="animationElement">
                                <p className="light">
                                    {
                                        nl2br(translations.digitalization_page_6_text)
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="mb-2 page2-img mobile mt-2">
                            <ImageRef className="image_digitalization" img={require('./../images/digitalization/page_6.png')} img2={require('./../images/digitalization/page_6@2x.png')} alt="Cloud image of the Bodyshake Digital Bodyshake Platform" />
                        </div>
                    </div>
                </section>
            </div>
            <Jumpbutton clickHandler={clickHandler} />
        </Layout>
    )
}


const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})

export default connect(mapStateToProps)(Digitalization)
